<template>
    <div class="size-6 flex flex-wrap columns">
        <div v-if="data.location && data.location.parts" class="col">
            <div class="rounded-m shadow bg-accent-3 flex align-center px-l py-s mb-s" >
                <LocationIcon :size="18" />
                <div class="ml-r">
                    {{ data.location.parts.country }},
                    <span class="color-secondary">{{ data.location.parts.city }}</span>
                </div>
            </div>
        </div>
        <div v-if="category" class="col">
            <div class="rounded-m shadow bg-accent-3 flex align-center px-l py-s" >
                <TagIcon :size="18" />
                <div class="ml-r">
                    {{ category.name }}
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import LocationIcon from 'vue-material-design-icons/MapMarker.vue';
import TagIcon from 'vue-material-design-icons/Tag.vue';

export default {
    name: 'LocationHeader',
    components: {
        LocationIcon,
        TagIcon,
    },
    props: {
        data: Object,
        cats: Array,
    },
    computed: {
        category() {
            if (this.data.category) {
                return this.cats.find((it) => it.id === this.data.category);
            }
            return false;
        },
    },
};
</script>

<style>

</style>
