<template>
    <div>
        <WaveDownBG>
            <slot name="navigation" />
            <Header v-if="modules[$route.name].includes('intro')" :title="article.name" :text="article.subtitle_en" type="small">
                <template v-slot:right>
                    <LocationHeader v-if="modules[$route.name].includes('intro')" :data="article" :cats="data.foundation_projects_categories" />
                </template>
            </Header>
        </WaveDownBG>

        <div class="c w-s my-xxl pb-xxl">
            <div v-if="typeof article.config_en === 'string'" class="richtext color-secondary leading-m l-up:pr-xl accentlink" v-html="article.config_en" />
        </div>

        <Impact v-if="modules[$route.name].includes('Impact')" class="mb-xxl" />
        <br />
    </div>
</template>

<script>
import WaveDownBG from '@/components/bgs/WaveDownSmall.vue';
import Header from '@/components/Header.vue';
import LocationHeader from '@/components/LocationHeader.vue';
import Impact from '@/components/Impact.vue';
import { strings } from 'p5-helpers';

export default {
    name: 'Article',
    components: {
        WaveDownBG,
        Header,
        LocationHeader,
        Impact,
    },
    data() {
        return {
            article: {},
            modules: {
                Project: ['locationHeader', 'Impact', 'intro'],
                Sanitation: ['Impact'],
            },
        };
    },
    beforeRouteEnter(to, from, next) {
        next((vm) => vm.getData());
    },
    beforeRouteUpdate(to, from, next) {
        next();
        this.getData();
    },
    methods: {
        getData() {
            if (window.PRERENDER_INJECTED) {
                const routedata = window.PRERENDER_INJECTED.routeData[this.$route.path];
                if (routedata) {
                    this.processData(routedata);
                }
            } else {
                const url = strings.replaceBrackets(this.$route.meta.article.endpoint, this.$route.params);
                this.$store.dispatch('get', url).then(this.processData);
            }
        },

        processData(r) {
            this.article = r;

            let schemas = [];
            if (this.$route.meta.article.schema) {
                if (typeof this.$route.meta.article.schema === 'function') {
                    schemas = this.$route.meta.article.schema(r, this);
                } else {
                    schemas = this.$route.meta.article.schema;
                }
            }
            this.$store.commit('setMeta', {
                data: {
                    title: r.meta_title_en,
                    description: r.meta_description_en,
                    keywords: (Array.isArray(r.meta_keywords_en) ? r.meta_keywords_en : []).map((it) => it.name).join(', '),
                },
                schemas,
            });
        },
    },
    computed: {
        data() {
            return this.$store.getters.data;
        },
    },
};
</script>
